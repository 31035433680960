// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-lessons-js": () => import("/opt/build/repo/src/templates/lessons.js" /* webpackChunkName: "component---src-templates-lessons-js" */),
  "component---src-templates-lesson-js": () => import("/opt/build/repo/src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angular-js": () => import("/opt/build/repo/src/pages/angular.js" /* webpackChunkName: "component---src-pages-angular-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

