import "prismjs/themes/prism-okaidia.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { injectGlobal } from 'react-emotion';

injectGlobal`

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight: 300;
    line-height: 1.8em;
  }

  strong {
    font-weight: 700 !important;
  }

`;